<template>
  <div>
    <b-card>
      <div class="border rounded p-2">
        <h4 class="mb-1">
          Program Image
        </h4>
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                :src="program.imageUrl "
                height="250"
                alt=""
                width="250"
              />
            </b-link>
          <!--/ avatar -->
          </b-media-aside>
          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-model="file"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              :state="Boolean(file)"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="fileSelected"
            />
            <!--/ upload button -->

            <!-- reset -->

            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
          </b-media-body>
        </b-media>
      </div>
      <b-form-group
        label="Name"
        label-for="name"
        class="my-2"
      >
        <b-form-input
          id="name"
          v-model="program.name"
        />

      </b-form-group>
      <b-form-group
        label="Description"
        label-for="desc"
        class="my-2"
      >
        <b-form-input
          id="desc"
          v-model="program.description"
        />

      </b-form-group>
      <b-row v-if="presenters.length > 0">
        <b-col cols="12">
          <div class="text-center my-1">
            <h3><b>Select a Presenter</b></h3>
          </div>
        </b-col>
        <b-col
          v-for="item in presenters"
          :key="item.id"
          cols="12"
          md="3"
        >
          <b-card
            :border-variant="program.programmerId === item.id ? 'primary' : 'secondary'"
            class="text-center cursor-pointer h-100"
            @click="program.programmerId = item.id"
          >
            <b-card-text>
              <img
                :src="item.profileImage"
                width="40"
                alt=""
              >
              <div class="mt-2">
                {{ item.name }}
              </div>

            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 mr-1"
        @click="method === 'edit' ? updateProgram() : createProgram()"
      >
        Save changes
      </b-button>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import {
  BCard, BMediaBody, BFormFile, BButton, BCardText, BMediaAside, BLink, BImg, BMedia, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import presenterStoreModule from '../../presenters/presentersStoreModule'
import programsStoreModule from '../programsStoreModule'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BMediaBody,
    BFormFile,
    BButton,
    BCardText,
    BMediaAside,
    BLink,
    BImg,
    BFormGroup,
    BMedia,
  },
  directives: {
    Ripple,
  },
  props: {
    method: {
      type: String,
    },
  },
  data() {
    return {
      file: null,
      profileFile: null,
      presenterStoreName: 'presenters',
      storeName: 'programs',
      presenters: [],
      program: {
        programmerId: null,
      },
    }
  },
  async  mounted() {
    await this.registerPresenterStore()
    await this.registerStore()
    this.fetchPresenters()

    if (this.method === 'edit') {
      this.fetchProgram()
    }
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fileSelected(payload) {
      const formData = new FormData()
      formData.append('file', payload)
      formData.append('postid', 2)
      formData.append('acc', 3)
      formData.append('platform', 'webadmin')
      this.isLoading = true

      await axios
        .post('/upload/admin', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          console.log(response)
          this.program.imageUrl = response.data.url
        })
        .catch(error => {
          console.log(error)
        })

      this.$mount()
    },
    async fetchPresenters() {
      this.presenters = await this.$store.dispatch(`${this.presenterStoreName}/fetchPresenters`, { limit: '10', page: 1, q: '' })
    },
    async fetchProgram() {
      this.program = await this.$store.dispatch(`${this.storeName}/fetchProgram`, this.$route.query.id)
    },
    async updateProgram() {
      try {
        this.$store.dispatch('programs/updateProgram', this.program)
        this.$bvToast.toast('Program updated successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        setTimeout(() => {
          this.$router.push({ name: 'Programs' })
        }, 2000)
      } catch (error) {
        this.$bvToast.toast('Getting an error while updating presenter.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    createProgram() {
      try {
        if (this.program.programmerId === null || this.program.programmerId === '') {
          this.$bvToast.toast('Please select a presenter.', {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'error',
            solid: true,
          })
          return
        }
        this.$store.dispatch('programs/createProgram', this.program)
        this.$bvToast.toast('Program created successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
        setTimeout(() => {
          this.$router.push({ name: 'Programs' })
        }, 2000)
      } catch (error) {
        this.$bvToast.toast('Getting an error while updating presenter.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, programsStoreModule)
    },
    registerPresenterStore() {
      if (!this.$store.hasModule(this.presenterStoreName)) this.$store.registerModule(this.presenterStoreName, presenterStoreModule)
    },
  },
}
</script>
